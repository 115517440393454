import React from 'react';
import TopBar from './TopBar';
import Footer from './Footer';
import '../styles/AboutUs.css'; // Ensure you create this CSS file for styling

const AboutUs = () => {
    return (
        <div className="about-us-container">
            <TopBar />
            <div className="about-us-content">
                <h1>About Us</h1>
                <p>
                    Welcome to our website! We are dedicated to rewarding you with Naira for completing simple tasks. Our goal is to make it easy and fun for you to earn rewards by engaging in various activities.
                </p>
                <h2>How It Works</h2>
                <p>
                    Our platform offers a range of tasks that you can complete to earn Naira. These tasks can include surveys, watching ads, or performing other simple actions. Each completed task earns you a certain amount of Naira, which accumulates in your account.
                </p>
                <h2>Our Mission</h2>
                <p>
                    We aim to provide a seamless and enjoyable experience for our users. By completing tasks, you not only earn rewards but also contribute to a vibrant community of users who enjoy similar activities.
                </p>
                <h2>Contact Us</h2>
                <p>
                    If you have any questions or need support, feel free to reach out to us at <a href="mailto:support@example.com">support@example.com</a>.
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;
