import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, get, update, onValue } from 'firebase/database';
import { db, auth } from '../firebase/config'; // Ensure this path is correct
import TopBar from './TopBar';
import Footer from './Footer';
import '../styles/Home.css'; // Import CSS for styling
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications

// Notification function
const notify = (type, message) => {
    switch(type) {
        case 'success':
            toast.success(message);
            break;
        case 'info':
            toast.info(message);
            break;
        case 'warning':
            toast.warn(message);
            break;
        case 'error':
            toast.error(message);
            break;
        default:
            toast(message);
    }
};

// Define an array of URLs
const adUrls = [
    'https://fugnugleedep.com/4/7857590',
    'https://auneechuksee.net/4/7857582',
    'https://www.highrevenuenetwork.com/mxwgxguc?key=af0f857c667e66b532f475b7833a005c',
    'https://www.highratecpm.com/cxp2pnf41g?key=47fe030ee77ae58ecc92e6ee6eff405f',
    'https://www.highratecpm.com/ckk8e4u3?key=ee6ab90467d69edce065f0347c5f174e',
    'https://feewostoo.com/4/7857597',
    'https://ptoaveloamo.net/4/7766416',
    'https://rigrimocmo.com/4/7825396'
];

const MAX_REQUESTS_PER_MINUTE = 1200; // Max requests per minute
const INTERVAL_MS = 60 * 1000; // 1 minute in milliseconds
const AD_WINDOW_TIMEOUT_MS = 2 * 60 * 1000; // 2 minutes in milliseconds

const Home = () => {
    const [totalCoins, setTotalCoins] = useState(0);
    const [naira, setNaira] = useState(0);
    const [username, setUsername] = useState('');
    const [requestCount, setRequestCount] = useState(0); // Track request count
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            if (auth.currentUser) {
                const userId = auth.currentUser.uid;
                const userRef = ref(db, 'users/' + userId);

                try {
                    const snapshot = await get(userRef);
                    if (snapshot.exists()) {
                        const userData = snapshot.val();
                        setUsername(userData.name);
                        setTotalCoins(userData.totalCoins || 0);
                        setNaira(userData.naira || 0); // Fetch Naira directly
                    } else {
                        console.log('No such user document!');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                console.log('User is not authenticated');
            }
        };

        fetchUserData();

        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            const userRef = ref(db, 'users/' + userId);

            // Set up a real-time listener to keep the UI updated
            const unsubscribe = onValue(userRef, (snapshot) => {
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    setUsername(userData.name);
                    setTotalCoins(userData.totalCoins || 0);
                    setNaira(userData.naira || 0); // Update Naira directly
                }
            });

            // Clean up the listener on component unmount
            return () => unsubscribe();
        }
    }, []);

    useEffect(() => {
        // Reset the request count every minute
        const resetRequestCount = setInterval(() => {
            setRequestCount(0);
        }, INTERVAL_MS);

        return () => clearInterval(resetRequestCount);
    }, []);

    const handleAdInteraction = async (increment) => {
        try {
            const userId = auth.currentUser.uid;
            const userRef = ref(db, 'users/' + userId);

            // Fetch the latest total coins from the database
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                const userData = snapshot.val();
                const newTotalCoins = (userData.totalCoins || 0) + increment;
                const newNaira = newTotalCoins * 0.1; // Assuming 0.1 Naira per coin

                await update(userRef, {
                    totalCoins: newTotalCoins,
                    naira: newNaira,
                });

                notify('success', `Congratulations! You earned ${increment} coins.`);
            } else {
                notify('error', 'Failed to fetch user data from the database.');
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            notify('error', 'Failed to update user data.');
        }
    };

    const attemptToOpenAd = (urlIndex = 0) => {
        if (urlIndex >= adUrls.length) {
            notify('error', 'Failed to open. Please try again later.');
            return;
        }

        const url = adUrls[urlIndex];

        try {
            const newWindow = window.open(url, '_blank', 'width=800,height=600');

            if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                notify('warning', 'Failed to open. Trying another link...');
                attemptToOpenAd(urlIndex + 1); // Try the next URL
                return;
            }

            const openedAt = Date.now(); // Track when the window was opened

            const checkLoadStatus = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(checkLoadStatus);
                    const duration = Date.now() - openedAt; // Calculate duration

                    if (duration >= AD_WINDOW_TIMEOUT_MS) {
                        notify('success', 'You interacted with the window after 2 minutes. Reward added.');
                        handleAdInteraction(100); // Reward the user for keeping the window open for 2 minutes
                    } else {
                        notify('info', 'No interaction with window  before 2 minutes. No reward added.');
                    }
                }
            }, 1000);

            // Check if the window is loaded and not blocked or errored
            const checkWindowLoad = setTimeout(() => {
                if (newWindow.location.href === 'about:blank' || newWindow.document.title === '403 Forbidden') {
                    clearInterval(checkLoadStatus);
                    newWindow.close();
                    notify('error', 'Failed to load ad. Trying another link...');
                    attemptToOpenAd(urlIndex + 1);
                }
            }, 3000); // Check after 3 seconds

            // Cleanup function to remove the event listener and timeout
            return () => {
                clearTimeout(checkWindowLoad);
            };
        } catch (error) {
            console.error('Error handling  opening:', error);
            notify('error', 'Error occurred. Trying another link...');
            attemptToOpenAd(urlIndex + 1); // Try the next URL
        }
    };

    const handleButtonClick = () => {
        if (!auth.currentUser) {
            notify('error', 'User is not authenticated.');
            return;
        }

        if (requestCount >= MAX_REQUESTS_PER_MINUTE) {
            notify('warning', 'Request limit reached. Please wait a minute.');
            return;
        }

        setRequestCount(prev => prev + 1);
        attemptToOpenAd(); // Start attempting to open ads from the first URL
    };

    return (
        <div className="home-container">
            <TopBar />
            <h2>Welcome to Your Dashboard, {username}</h2>
            <p className="total-coins">Total Coins: {totalCoins}</p>
            <p className="naira-value">Total Naira Value: ₦{naira.toFixed(2)}</p> {/* Display Naira value */}
            <div className="button-container">
                <button 
                    className="action-button" 
                    onClick={handleButtonClick}
                >
                    Get coins
                </button>
                <button 
                    className="action-button" 
                    onClick={handleButtonClick}
                >
                    Coins mining
                </button>
                <button 
                    className="action-button" 
                    onClick={handleButtonClick}
                >
                    More coins
                </button>
            </div>
            
            <ToastContainer /> {/* Include ToastContainer */}
            <Footer/>
        </div>
    );
};

export default Home;
