import React from 'react';
import '../styles/ForgotPassword.css'; // Import external CSS file

const ForgotPassword = () => {
    return (
        <div className="forgot-password-container">
            <h2>Forgot Password</h2>
            <form>
                <label>Email</label>
                <input type="email" />

                <button type="submit">Reset Password</button>
            </form>
        </div>
    );
}

export default ForgotPassword;
