// GetCoins.js
import React, { useState } from 'react';
import TopBar from './TopBar';
import '../styles/GetCoins.css';

const GetCoins = () => {
    const [coins, setCoins] = useState(0);

    const handleMediaNet = () => {
        const mediaNetUrl = 'YOUR_MEDIA_NET_LINK';
        openAdWindow(mediaNetUrl, 50); // Reward 50 coins for Media.net
    };

    const handlePropellerAds = () => {
        const propellerAdsUrl = 'YOUR_PROPELLER_ADS_LINK';
        openAdWindow(propellerAdsUrl, 75); // Reward 75 coins for PropellerAds
    };

    const handleUnityAds = () => {
        const unityAdsUrl = 'YOUR_UNITY_ADS_LINK';
        openAdWindow(unityAdsUrl, 150); // Reward 150 coins for Unity Ads
    };

    const handleMontage = () => {
        const montageUrl = 'YOUR_MONTAGE_LINK';
        openAdWindow(montageUrl, 100); // Reward 100 coins for Montage
    };

    const handleAdsterra = () => {
        const adsterraUrl = 'https://www.highrevenuenetwork.com/mxwgxguc?key=af0f857c667e66b532f475b7833a005c';
        openAdWindow(adsterraUrl, 120); // Reward 120 coins for Adsterra
    };

    const openAdWindow = (url, rewardCoins) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

        if (newWindow) {
            const interval = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(interval);
                    setCoins(coins + rewardCoins);
                }
            }, 1000);
        } else {
            console.error("Failed to open new window. It may have been blocked by a pop-up blocker.");
        }
    };

    const handleButtonClick = (amount) => {
        setCoins(coins + amount);
    };

    return (
        <div>
            <TopBar />
            <div className="get-coins-container">
                <h2>Total Coins: {coins}</h2>
                <div className="button-container">
                    <button onClick={() => handleButtonClick(10)}>+10 Coins</button>
                    <button onClick={() => handleButtonClick(50)}>+50 Coins</button>
                    <button onClick={() => handleButtonClick(100)}>+100 Coins</button>
                    <button onClick={handleMediaNet}>Media.net</button>
                    <button onClick={handlePropellerAds}>PropellerAds</button>
                    <button onClick={handleUnityAds}>Unity Ads</button>
                    <button onClick={handleMontage}>Montage</button>
                    <button onClick={handleAdsterra}>Adsterra</button>
                </div>
            </div>
        </div>
    );
};

export default GetCoins;
