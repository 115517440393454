import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set, get, update } from 'firebase/database'; // Import Realtime Database methods
import { auth, db } from '../firebase/config'; // Ensure Firebase is properly configured
import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating unique referral codes
import '../styles/Register.css'; // Import CSS file for styles

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        const { name, email, phone, password } = formData;
        if (!name || !email || !phone || !password) {
            return 'All fields are required';
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        // Validate form fields
        const validationError = validateForm();
        if (validationError) {
            setLoading(false);
            setError(validationError);
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            // Generate a unique referral code for the new user
            const referralCode = uuidv4();

            // Get the referrer code from URL query parameters
            const searchParams = new URLSearchParams(window.location.search);
            const referrerCode = searchParams.get('referral');

            // Calculate naira equivalent (0.1 naira per coin)
            const totalCoins = 100; // New users get 100 coins
            const nairaEquivalent = totalCoins * 0.1;

            // Write user data to Realtime Database
            await set(ref(db, `users/${user.uid}`), {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                totalCoins: totalCoins,
                naira: nairaEquivalent, // Include naira equivalent
                referralCode: referralCode, // Save referral code
                referralCoins: 0, // Initialize referralCoins
                referredBy: referrerCode, // Track who referred the user
            });

            // If a referrer code exists, update the referrer's coins and referrals
            if (referrerCode) {
                // Fetch the referrer's data
                const referrerSnapshot = await get(ref(db, `users`));
                if (referrerSnapshot.exists()) {
                    const users = referrerSnapshot.val();
                    const referrer = Object.values(users).find(user => user.referralCode === referrerCode);
                    if (referrer) {
                        const referrerId = Object.keys(users).find(key => users[key].referralCode === referrerCode);

                        // Update referrer's referralCoins and add new user to referrer’s list
                        const referrerRef = ref(db, `users/${referrerId}`);
                        await update(referrerRef, {
                            referralCoins: (referrer.referralCoins || 0) + 100,
                            referrals: {
                                [user.uid]: true, // Add the new user’s ID to the referrer's referrals list
                                ...referrer.referrals, // Retain existing referrals
                            },
                        });
                    }
                }
            }

            setLoading(false);
            navigate('/home');
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };

    return (
        <div className="register-container">
            <h2>Register</h2>
            <form onSubmit={handleSubmit}>
               

                <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
               
                <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Registering...' : 'Register'}
                </button>
            </form>
        </div>
    );
};

export default Register;
