import React, { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { auth, db } from '../firebase/config';
import TopBar from './TopBar';
import '../styles/History.css';
import Footer from './Footer';

const History = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [withdrawalHistory, setWithdrawalHistory] = useState([]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                fetchWithdrawalHistory(user.uid);
            } else {
                setCurrentUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchWithdrawalHistory = async (userId) => {
        try {
            const historyRef = ref(db, `withdrawals/${userId}`);
            const snapshot = await get(historyRef);
            if (snapshot.exists()) {
                const historyData = snapshot.val();
                const historyArray = Object.keys(historyData).map(key => ({
                    id: key,
                    ...historyData[key],
                }));
                setWithdrawalHistory(historyArray);
            } else {
                console.log('Withdrawal history not found');
            }
        } catch (error) {
            console.error('Error fetching withdrawal history:', error);
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    if (!currentUser) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <TopBar />
            <div className="withdraw-history-container">
                <h2>Withdrawal History</h2>
                {withdrawalHistory.length === 0 ? (
                    <p>No withdrawal history</p>
                ) : (
                    <table className="withdraw-history-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Amount</th>
                                <th>Naira Equivalent</th>
                                <th>Account Number</th>
                                <th>Bank Name</th>
                                <th>Credited</th>
                            </tr>
                        </thead>
                        <tbody>
                            {withdrawalHistory.map((item, index) => (
                                <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td>{(item.date)}</td>
                                    <td>{item.time}</td>
                                    <td>{item.amount} coins</td>
                                    <td>{item.naira} Naira</td>
                                    <td>{item.accountNumber}</td>
                                    <td>{item.bankName}</td>
                                    <td style={{ 
                                        backgroundColor: item.credited ? 'green' : 'brown', 
                                        color: 'white', 
                                        textAlign: 'center',
                                        padding: '8px' 
                                    }}>
                                        {item.credited ? 'Account Credited' : 'Pending'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default History;
