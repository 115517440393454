import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import Modal from 'react-modal'; // Import react-modal

Modal.setAppElement('#root'); // Set the app root element for accessibility

const BankVerificationForm = () => {
    const location = useLocation();
    const { amount, userId, coins } = location.state || {}; // Retrieve the amount, userId, and coins from the state
    const [accountNumber, setAccountNumber] = useState('');
    const [bankCode, setBankCode] = useState('');
    const [accountName, setAccountName] = useState('');
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    const banks = [
        { name: 'Access Bank', code: '044' },
        { name: 'Citibank', code: '023' },
        { name: 'Diamond Bank', code: '063' },
        { name: 'Ecobank Nigeria', code: '050' },
        { name: 'Fidelity Bank Nigeria', code: '070' },
        { name: 'First Bank of Nigeria', code: '011' },
        { name: 'First City Monument Bank', code: '214' },
        { name: 'Guaranty Trust Bank', code: '058' },
        { name: 'Heritage Bank Plc', code: '030' },
        { name: 'Jaiz Bank', code: '301' },
        { name: 'Keystone Bank Limited', code: '082' },
        { name: 'Providus Bank Plc', code: '101' },
        { name: 'Polaris Bank', code: '076' },
        { name: 'Stanbic IBTC Bank Nigeria Limited', code: '221' },
        { name: 'Standard Chartered Bank', code: '068' },
        { name: 'Sterling Bank', code: '232' },
        { name: 'Suntrust Bank Nigeria Limited', code: '100' },
        { name: 'Union Bank of Nigeria', code: '032' },
        { name: 'United Bank for Africa', code: '033' },
        { name: 'Unity Bank Plc', code: '215' },
        { name: 'Wema Bank', code: '035' },
        { name: 'Zenith Bank', code: '057' },
    ];

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('http://localhost:8000/api/verify-account/', {
            account_number: accountNumber,
            bank_code: bankCode,
            user_id: userId,
            amount: amount
        })
        .then(response => {
            setAccountName(response.data.account_name);
            setShowModal(true); // Show modal on successful verification
            setError('');
        })
        .catch(error => {
            setError(error.response.data.error || 'There was an error verifying the account.');
            setAccountName('');
        });
    };

    const handleConfirm = () => {
        // Handle confirmation action (e.g., finalize withdrawal)
        // For example, navigate to a success page or update withdrawal status
        setShowModal(false); // Close modal after confirmation
    };

    return (
        <div>
            <h1>Bank Account Verification</h1>
            <p>Amount to Withdraw: {amount} coins</p> {/* Display amount here */}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Account Number:</label>
                    <input 
                        type="text" 
                        value={accountNumber} 
                        onChange={(e) => setAccountNumber(e.target.value)} 
                        required 
                    />
                </div>
                <div>
                    <label>Bank:</label>
                    <select 
                        value={bankCode} 
                        onChange={(e) => setBankCode(e.target.value)} 
                        required
                    >
                        <option value="">Select a bank</option>
                        {banks.map((bank, index) => (
                            <option key={index} value={bank.code}>{bank.name}</option>
                        ))}
                    </select>
                </div>
                <button type="submit">Verify</button>
            </form>

            {/* Modal for displaying account details */}
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                contentLabel="Confirm Withdrawal Modal"
            >
                <h2>Confirm Withdrawal</h2>
                <p>Account Number: {accountNumber}</p>
                <p>Bank Name: {banks.find(bank => bank.code === bankCode)?.name}</p>
                <p>Account Name: {accountName}</p>
                <p>Amount to Withdraw: {amount} coins</p>
                <button onClick={handleConfirm}>Confirm</button>
                <button onClick={() => setShowModal(false)}>Cancel</button>
            </Modal>

            {error && <p style={{color: 'red'}}>Error: {error}</p>}
            <Footer />
        </div>
    );
};

export default BankVerificationForm;
