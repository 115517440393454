import React, { useState, useEffect } from 'react';
import { ref, get, set, push } from 'firebase/database';
import { auth, db } from '../firebase/config';
import TopBar from './TopBar';
import '../styles/WithdrawForm.css';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MINIMUM_WITHDRAWAL_COINS = 10000; // 10,000 coins equals 1,000 Naira
const COIN_TO_NAIRA_CONVERSION_RATE = 0.1*MINIMUM_WITHDRAWAL_COINS; // 1 coin = 0.1 Naira

const WithdrawForm = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [coins, setCoins] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [error, setError] = useState('');
    const [bankDetails, setBankDetails] = useState({
        bankName: '',
        accountNumber: '',
    });
    const [withdrawForm, setWithdrawForm] = useState({ email: '' });
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                fetchUserCoins(user.uid);
                fetchUserDetails(user.uid);
            } else {
                setCurrentUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (currentUser) {
            fetchUserDetails(currentUser.uid);
            fetchTotalCoins(currentUser.uid);
        }
    }, [currentUser]);

    const fetchUserDetails = async (userId) => {
        try {
            const userSnapshot = await get(ref(db, `users/${userId}`));
            if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                setWithdrawForm({
                    email: userData.email || 'Email not available',
                });
            } else {
                console.log('User data not found');
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const fetchUserCoins = async (userId) => {
        try {
            const userRef = ref(db, `users/${userId}/totalCoins`);
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                setCoins(snapshot.val());
            } else {
                console.log('User coins not found');
            }
        } catch (error) {
            console.error('Error fetching user coins:', error);
        }
    };

    const fetchTotalCoins = async (userId) => {
        try {
            const userRef = ref(db, `users/${userId}/totalCoins`);
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                setCoins(snapshot.val());
            } else {
                console.log('Total coins not found');
            }
        } catch (error) {
            console.error('Error fetching total coins:', error);
        }
    };

    const handleChange = (e) => {
        setWithdrawAmount(e.target.value);
    };

    const handleBankDetailsChange = (e) => {
        const { name, value } = e.target;
        setBankDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const amountToWithdraw = parseInt(withdrawAmount);

        if (amountToWithdraw <= 0 || amountToWithdraw > coins) {
            setError('Invalid amount or insufficient coins');
            return;
        }

        if (amountToWithdraw < MINIMUM_WITHDRAWAL_COINS) {
            setError(`Minimum withdrawal amount is ${MINIMUM_WITHDRAWAL_COINS} coins`);
            return;
        }

        const date = new Date();
        const formattedDate = formatDate(date);
        const formattedTime = formatTime(date);
        const nairaEquivalent = amountToWithdraw * COIN_TO_NAIRA_CONVERSION_RATE;

        try {
            const userId = currentUser.uid;
            const newWithdrawRef = push(ref(db, `withdrawals/${userId}`));
            await set(newWithdrawRef, {
                userId,
                email: withdrawForm.email,
                amount: amountToWithdraw,
                naira: nairaEquivalent,
                credited: false,
                date: formattedDate,
                time: formattedTime,
                bankName: bankDetails.bankName,
                accountNumber: bankDetails.accountNumber,
            });

            const updatedTotalCoins = coins - amountToWithdraw;
            await set(ref(db, `users/${userId}/totalCoins`), updatedTotalCoins);

            toast.success('Withdrawal successful! Redirecting to home page...');
            setTimeout(() => navigate('/home'), 3000);
        } catch (error) {
            console.error('Error processing withdrawal:', error);
            setError('An error occurred while processing your withdrawal.');
        }
    };

    if (!currentUser) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <TopBar />
            <div className="withdraw-container">
                <ToastContainer />
                <h2>Withdraw Coins</h2>
                <form onSubmit={handleSubmit} className="withdraw-form">
                    <div className="form-group">
                        <label>Email address:</label>
                        <p>{withdrawForm.email}</p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="amount">Amount (Minimum {MINIMUM_WITHDRAWAL_COINS} coins)</label>
                       
                        <input
                            type="number"
                            className="form-control"
                            id="amount"
                            name="amount"
                            value={withdrawAmount}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
    <label htmlFor="bankName">Bank Name</label>
    <select
        name="bankName"
        value={bankDetails.bankName}
        onChange={handleBankDetailsChange}
        required
    >
        <option value="">Select Bank</option>
        <option value="GT Bank">GT Bank</option>
        <option value="Access Bank">Access Bank</option>
        <option value="UBA">UBA</option>
        <option value="First Bank">First Bank</option>
        <option value="Zenith Bank">Zenith Bank</option>
        <option value="Stanbic IBTC Bank">Stanbic IBTC Bank</option>
        <option value="Ecobank">Ecobank</option>
        <option value="Fidelity Bank">Fidelity Bank</option>
        <option value="Union Bank">Union Bank</option>
        <option value="Wema Bank">Wema Bank</option>
        <option value="Standard Chartered Bank">Standard Chartered Bank</option>
        <option value="Citibank">Citibank</option>
        <option value="Heritage Bank">Heritage Bank</option>
        <option value="Jaiz Bank">Jaiz Bank</option>
        <option value="Skye Bank">Skye Bank</option>
        <option value="Unity Bank">Unity Bank</option>
        <option value="Keystone Bank">Keystone Bank</option>
        <option value="Suntrust Bank">Suntrust Bank</option>
        <option value="Palmspring Bank">Palmspring Bank</option>
        <option value="Polaris Bank">Polaris Bank</option>
        {/* Add any additional banks if necessary */}
    </select>
</div>

                    <div className="form-group">
                        <label htmlFor="accountNumber">Account Number</label>
                        <input
                            type="text"
                            className="form-control"
                            id="accountNumber"
                            name="accountNumber"
                            value={bankDetails.accountNumber}
                            onChange={handleBankDetailsChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Total Coins:</label>
                        <p>{coins}</p>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <button type="submit" className="btn btn-primary">
                        Withdraw
                    </button>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default WithdrawForm;
