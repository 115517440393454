import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase/config'; // Assuming auth is initialized from Firebase
import '../styles/TopBar.css';

const TopBar = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await auth.signOut(); // Sign out the user
            navigate('/'); // Redirect to the login screen ("/")
        } catch (error) {
            console.error('Error signing out:', error.message);
            // Handle error if needed
        }
    };

    return (
        <div className="topbar">
            <div className="topbar-logo">Tap Naira</div>
            <div className="topbar-menu-container">
                <div className="topbar-menu">
                    <Link to="/home">Home</Link>
                    <Link to="/withdraw">Withdraw</Link>
                    <Link to="/history">History</Link>
                    <Link to="/settings">Settings</Link>
                    <Link to="/refer">Refer</Link>
                    <button className="topbar-button" onClick={handleLogout}>Logout</button>
                </div>
                
            </div>
        </div>
    );
};

export default TopBar;
