import React, { useState, useEffect } from 'react';
import { ref, push, get } from 'firebase/database';
import { auth, db } from '../firebase/config'; // Ensure Firebase auth and db are initialized
import TopBar from './TopBar';
import '../styles/Contact.css';
import Footer from './Footer';

const Contact = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                fetchUserData(user.uid);
            } else {
                setCurrentUser(null);
                setUserData({
                    username: '',
                    email: '',
                    phone: '',
                });
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchUserData = async (userId) => {
        try {
            const userRef = ref(db, `users/${userId}`);
            const userSnapshot = await get(userRef);
            if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                setUserData({
                    username: userData.username || 'Username not available',
                    email: userData.email || 'Email not available',
                    phone: userData.phone || 'Phone not available',
                });
            } else {
                console.log('User data not found');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'message') setMessage(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        try {
            // Save message to Firebase Realtime Database
            const messagesRef = ref(db, 'messages');
            await push(messagesRef, {
                username: userData.username,
                email: userData.email,
                phone: userData.phone,
                message,
                timestamp: new Date().toISOString(), // Include timestamp
            });

            // Reset form fields after successful submission
            setMessage('');
            setSuccessMessage('Message sent successfully!');
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again later.');
        }
    };

    if (!currentUser) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <TopBar />
            <div className="contact-container">
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            className="form-control"
                            id="username"
                            name="username"
                            value={userData.username}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email address</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={userData.email}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={userData.phone}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            className="form-control"
                            id="message"
                            name="message"
                            value={message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}
                    <button type="submit" className="btn btn-primary">
                        Send Message
                    </button>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default Contact;
