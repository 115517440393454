import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config.js'; // Import Firebase auth instance
import '../styles/Login.css'; // Import external CSS file

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const navigateToRegister = () => {
        navigate('/register');
    };

    const navigateToForgotPassword = () => {
        navigate('/forgot-password');
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        
        try {
            // Sign in user with email and password
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            // Redirect to home page or dashboard upon successful login
            navigate('/home');
        } catch (error) {
            setError('Invalid credentials. Please try again.');
            console.error('Error signing in:', error);
        }
    };

    return (
        <div className="login-container">
            <h2>Login</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleLogin}>
                <label>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />

                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />

                <button type="submit">Login</button>
            </form>

            <div className="login-options">
                <button className="forgot-password-btn" onClick={navigateToForgotPassword}>
                    Forgot Password
                </button>
                <button className="register-btn" onClick={navigateToRegister}>
                    Register
                </button>
            </div>
        </div>
    );
};

export default Login;
